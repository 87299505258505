.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.react-tabs__tab--selected {
   background: transparent;
   margin-bottom: -1.2px;
   border-bottom: 2px solid #016db9;
}
.react-tabs__tab--selected > span {
   color: #e7e9ec !important;
}

.react-tabs [role='tab'] {
   display: inline-block;
   border-top: none;
   border-left: none;
   border-right: none;
   padding: 10px;
   margin-right: 40px;
   bottom: 0px;
   position: relative;
   cursor: pointer;
   list-style: none;
}
.react-tabs [role='tablist'] {
   border-top: none;
   border-left: none;
   border-right: none;
   border-bottom: 2px solid #2a2c34;
   margin: 10px 40px 0px 24px !important;
   display: flex;
}
.react-tabs {
   padding: 24px 0px;
}

.react-tabs__tab {
   padding: 6px 3px !important;
}

.react-tabs-tabs-list {
   border-top: none;
   border-left: none;
   border-right: none;
   border-bottom: 2px solid #2a2c34;
   margin: 10px 40px 0px 24px !important;
   display: flex;
   width: 256px !important;
   justify-content: center;
}

.ModalContent {
   position: absolute;
   border: none;
   background: transparent;
   overflow: auto;
   border-radius: 4px;
   outline: none;
   padding: 0px;
   transform-origin: center;
   transform: translate(40%, 35%);
   inset: 0px !important;
   overflow: auto !important;
 }

.Overlay {
   position: fixed;
   inset: 0px;
   background-color: rgba(0, 0, 0, 0.7);
   z-index: 2000;
   display: flex !important;
   justify-content: center !important;
   margin-left: 0px !important;
   transition: opacity 500ms ease-in-out;
}